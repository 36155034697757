import React, { useState, useEffect } from "react";

import { DiseaseComponent, LoadingComponent } from "components";
import { Tables } from "constants/index";
import { get, update } from "config/firebase";

const SearchBoxComponent = () => {
  const [searchValue, setSearchValue] = useState();
  const [searchResults, setSearchResults] = useState();
  const [selectedDisease, setSelectedDisease] = useState();
  const [diseases, setDiseases] = useState();
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    async function getDiseases () {
      setLoading(true);
      const res = await get(Tables.DISEASES);
      if (res.length) {
        setDiseases(res);
      }
      setLoading(false);
    }
    if (!diseases) {
      getDiseases();
    }
  }, []);

  const handleChange = (keyword) => {
    const re = new RegExp(keyword, "i");
    const res = diseases.filter((disease) => disease.searchText.match(re));
    setSearchResults(res);
    setSearchValue(keyword);
    setIsTyping(true);
  };

  const redirectTo = async (res) => {
    await update(Tables.DISEASES, res.id, {
      searchCount: res.searchCount + 1
    });
    setSearchValue(res.name);
    setSelectedDisease(res);
    setSearchResults("");
    setIsTyping(false);
  };

  const clearSearch = () => {
    setSearchResults("");
    setSelectedDisease("");
    setSearchValue("");
  };

  return (
    <>
      <div className="search-wrapper">
        {loading && <LoadingComponent />}
        <div className="search-box d-flex flex-row justify-content-center">
          <div className="input-group-prepend">
            <span className="input-group-text" id="search-city">
              <i className="fa fa-search" />
            </span>
          </div>
          <div className="search-box-wrapper">
            <input
              type="text"
              className="py-3"
              placeholder="Type your symptom here"
              aria-label="Type your symptom here"
              aria-describedby="search-city"
              onChange={(e) => handleChange(e.target.value)}
            />
            {searchValue && isTyping && (
              <div className="search-results">
                {searchResults.length
                  ? (
                      searchResults.map((res, idx) => (
                    <div
                      className="ps-4 py-2 pointer"
                      key={`${res.name}_${idx}`}
                      onClick={(e) => redirectTo(res)}
                    >
                      <div className="fw-500">{res.name}</div>
                      <div className="">{res.description.slice(0, 75)}...</div>
                    </div>
                      ))
                    )
                  : (
                  <div className="ps-3 py-2">No results found</div>
                    )}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row  justify-content-center">
          <button
            className="btn btn-violet mx-2"
            onClick={(e) => handleChange(searchValue)}
          >
            Search
          </button>
          <button className="btn btn-secondary mx-2" onClick={clearSearch}>
            Clear
          </button>
        </div>
      </div>
      {selectedDisease && <DiseaseComponent disease={selectedDisease} />}
    </>
  );
};

export default SearchBoxComponent;
