import React, { useState, useEffect } from "react";

import { LoadingComponent } from "components";
import { Tables } from "constants/index";
import { firestore, getQuery } from "config/firebase";
import { collection, query, where } from "config/firestore";

const DiseaseComponent = ({ disease }) => {
  const [dis, setDis] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getDisease () {
      setLoading(true);
      const q = query(
        collection(firestore, Tables.DISEASES),
        where("name", "==", disease.name)
      );
      const res = await getQuery(q);
      if (res.length) {
        setDis(res[0]);
      }
      setLoading(false);
    }

    if (!dis || dis.name !== disease.name) {
      getDisease();
    }
  }, [disease]);

  return !dis || loading
    ? (
    <LoadingComponent />
      )
    : (
    <div className="container">
      <div className="h1 container text-center pt-5 mt-5 fw-400">
        {dis.name}
      </div>
      {dis?.htmlText
        ? (
        <div dangerouslySetInnerHTML={{ __html: dis.htmlText }}></div>
          )
        : (
        <div className="container justify-content-center pt-2">
          {dis.description}
        </div>
          )}
    </div>
      );
};

export default DiseaseComponent;
