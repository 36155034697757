import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { LocalRoutes } from "routes";

const App = () => {
  return (
    <div>
      <Router>
        <LocalRoutes />
      </Router>
    </div>
  );
};

export default App;
