import React from "react";
import "./style.css";

import { StaticHeaderComponent } from "components";
import SearchBoxComponent from "./SearchBoxComponent";

const HomeComponent = () => {
  return (
    <>
      <div>
        <StaticHeaderComponent />
        <div className="container text-center pt-5 mt-5 fs-22 fw-400">
          AI-powered self care. Search 100+ diseases based on your symptoms.
          <div className="container text-center pt-3 fs-12 fw-500">
            Partnered with{" "}
            <a
              href="https://medoc.life"
              target="_blank"
              rel="noreferrer"
              className="text-violet"
            >
              Medoc.life
            </a>
          </div>
        </div>
        <SearchBoxComponent />
        <div className="container justify-content-center pt-5 mt-5 d-none">
          <form className="col-xl-6 col-lg-6 col-md-6 container">
            <div className="input-group input-group-lg">
              <input
                type="text"
                className="form-control"
                placeholder="Type your symptom"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              <span className="input-group-text" id="basic-addon1">
                Search
              </span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
