import React from "react";
import { Routes, Route } from "react-router-dom";

import { HomeComponent } from "components";

export const LocalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
    </Routes>
  );
};

export default Routes;
