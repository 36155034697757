import { initializeApp, getApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  serverTimestamp,
  connectFirestoreEmulator
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

let config = {};
if (process.env.REACT_APP_ENV === "development") {
  config = {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_ID
  };
}

if (process.env.REACT_APP_ENV === "production") {
  config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
}
// if (!firebase.apps.length) {
export const app = initializeApp(config);
// }

export const firestore = getFirestore(app);
export const storage = getStorage();
export const functions = getFunctions(getApp());

if (
  process.env.REACT_APP_ENV === "local" ||
  window.location.hostname === "localhost"
) {
  connectFunctionsEmulator(functions, window.location.hostname, 5001);
  connectFirestoreEmulator(firestore, window.location.hostname, 8080);
  // app.functions().useFunctionsEmulator("http://localhost:5001");
  // app.firestore().settings({
  //   host: "localhost:8080",
  //   ssl: false,
  // });
}

export const uploadFile = (file, type, callback) => {
  const fileName = file.name + "_" + Date.now();
  let imageRef = ref(storage, `app/avatars${fileName}`);
  if (type === "audio") {
    imageRef = ref(storage, `app/audios${fileName}`);
  }
  if (type === "image") {
    imageRef = ref(storage, `app/images${fileName}`);
  }
  const metadata = {
    contentType: file.type,
    contentSize: file.size
  };
  const uploadTask = uploadBytesResumable(imageRef, file, metadata);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.floor(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setBtnUpload(progress + "%");
      console.log(progress + "%");
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
        default:
          break;
      }
    },
    (err) => {
      switch (err.code) {
        case "storage/unauthorized":
          err.message = "Unauthorized user";
          break;
        case "storage/cancelled":
          err.message = "Upload cancelled";
          break;
        case "storage/unknown":
          err.message = "Unknown error occured";
          console.log("unknown error occured, inspect err.serverResponse");
          break;
        default:
          break;
      }
      // callback('', new Error(err))
    },
    (res) => {
      getDownloadURL(uploadTask.snapshot.ref).then((fileUrl) =>
        callback(fileUrl)
      );
    }
  );
};

export const removeFile = (imageUrl) => {
  const imageRef = ref(storage, imageUrl);
  return imageRef
    .delete()
    .then((suc) => formatResult(200, "Deleted successfully"))
    .catch((err) => err && formatResult(422, "Delete failed"));
};

export const add = (table, data) => {
  data.createdAt = new Date().getTime();
  data.updatedAt = new Date().getTime();
  const docRef = collection(firestore, table);
  return setDoc(docRef, data)
    .then((res) => {
      return formatResult(200, "Successfully created", {
        id: res.id,
        path: res.path
      });
    })
    .catch((e) => formatResult(500, "Something went wrong"));
};

export const update = (table, id, data) => {
  data.updatedAt = new Date().getTime();
  const docRef = doc(firestore, table, id);
  return updateDoc(docRef, data)
    .then((res) => formatResult(200, "Successfully updated", res))
    .catch((e) => formatResult(422, e.message));
};

export const get = (table, id = "all") => {
  const snapshot = getDocs(collection(firestore, table));

  return snapshot
    .then((results) => {
      const object = [];
      results.forEach((doc) => {
        object.push({
          id: doc.id,
          ...doc.data()
        });
      });
      return object.sort((a, b) => b.createdAt - a.createdAt);
    })
    .catch((err) => {
      console.error(err);
      return { error: err.code };
    });
};

export const getQuery = async (customQuery = null) => {
  return getDocs(customQuery)
    .then((results) => {
      const object = [];
      results.forEach((doc) => {
        object.push({
          id: doc.id,
          ...doc.data()
        });
      });
      return object.sort((a, b) => a.createdAt - b.createdAt);
    })
    .catch((err) => {
      console.error(err);
      return { error: err.code };
    });
};

export const getId = (table, id) => {
  const docRef = doc(firestore, table, id);
  return getDoc(docRef)
    .then((doc) =>
      doc.exists ? doc.data() : formatResult(404, "No data found")
    )
    .catch((err) => formatResult(err.code, err.message));
};

export const timestamp = serverTimestamp();

/* Common code */
function formatResult (status, message, data = {}) {
  return { status, message, data };
}
