import React from "react";

import { Routes } from "constants/index";

const StaticHeaderComponent = () => {
  return (
    <div className="static-header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="h5 text-violet py-2" href="#">
            selfcare
            <small className="fs-12 pl-2 text-secondary">&nbsp; ver 1.0</small>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-none">
              <li className="nav-item">
                <a className="nav-link btn btn-violet" href={Routes.DEFAULT}>
                  Login / Signup
                </a>
              </li>
              <li className="nav-item dropdown d-none">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
